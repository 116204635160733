import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import queryString from 'query-string';
import { useNavigate } from "react-router-dom";

import { Grid } from '@mui/material';
import List from '@mui/material/List';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import classifiedStackSizes3p from './data/classifiedStackSizes3p.json';

export default function SolutionSelector(props) {

    const [selectedBigStack, setSelectedBigStack] = useState(-1);
    const [selectedSmallStack, setSelectedSmallStack] = useState(-1);
    const [selectedStack, setSelectedStack] = useState(-1);

    const navigate = useNavigate();
    const { urlParamsStr } = useParams()
    

    function handleStackSizeClick(stackSizes) {
        let urlParams = queryString.parse(urlParamsStr)
        urlParams.gameName = stackSizes[0] + "_" + stackSizes[1] + "_" + stackSizes[2] +"_bb3p-nbIter10pow8"
        urlParams.nodeId = 1
        navigate("/getNodeGame/" + queryString.stringify(urlParams) ,  { replace: true })
    }


    return (

        <Grid container marginLeft={5} sx={{height:300}}>
            <Grid item xs={2} >
                <List
                    sx={{ maxWidth: 200  }}
                    subheader={
                        <ListSubheader   component="div" id="game-type">
                            Game Type
                        </ListSubheader>
                    }
                >
                    <ListItemButton
                        onClick={() => { console.log("ok") }}
                    >
                        <ListItemText primary={'3 Player'} />
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => { console.log("ok") }}
                    >
                        <ListItemText primary={'Heads Up'} />
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => { console.log("ok") }}
                    >
                        <ListItemText primary={'Heads Up reverse'}
                            secondary={"after button fold"} />
                    </ListItemButton>
                </List>

            </Grid>
            <Grid  item xs={2}>
                <List
                    sx={{ maxWidth: 200  }}
                    subheader={
                        <ListSubheader component="div" id="Bigest-stack-size">
                            Bigest Stack Size
                        </ListSubheader>
                    }
                >
                    {classifiedStackSizes3p.bigestStackSizesArr.map(
                        (bigestStackSizes, index) => (
                            <ListItemButton
                            sx={{borderRadius:3}}
                                selected={index == selectedBigStack ? true : false}
                                onClick={() => { setSelectedBigStack(index) ; setSelectedSmallStack(-1); setSelectedStack(-1); }}
                            >

                                <ListItemText primary={bigestStackSizes} />
                            </ListItemButton>
                        )
                    )}
                </List>

            </Grid>

            <Grid item xs={2} >

                {selectedBigStack != -1 ? (
                    <div style={{
                        height: "800px",
                        flexFlow: "column wrap",
                        overflow: "auto",
                    }}>
                    <List
                        sx={{ maxWidth: 200  }}
                        subheader={
                            <ListSubheader component="div" id="smallest-stack-size">
                                Smallest Stack Size
                            </ListSubheader>
                        }
                    >
                        {classifiedStackSizes3p.smalestStackSizeArr[selectedBigStack].map(
                            (bigestStackSizes, index) => (
                                <ListItemButton
                                    selected={index == selectedSmallStack ? true : false}
                                    onClick={() => { setSelectedSmallStack(index); setSelectedStack(-1); }}
                                >

                                    <ListItemText primary={bigestStackSizes} />
                                </ListItemButton>
                            )
                        )}
                    </List>
                    </div>)
                    :
                    ""

                }



            </Grid>
            <Grid item xs={2}>

                {selectedSmallStack != -1 ? (
                    <List
                        sx={{ maxWidth: 200 }}
                        subheader={
                            <ListSubheader component="div" id="stack-size"  sx={{lineHeight: "15px" }}>
                                Stack Sizes: <br />sb-bb-btn
                            </ListSubheader>
                        }
                    >
                        {classifiedStackSizes3p.classifiedStackSizes[selectedBigStack][selectedSmallStack].map(
                            (stackSizes, index) => (
                                <ListItemButton
                                    selected={index == selectedStack ? true : false}
                                    onClick={() => { handleStackSizeClick(stackSizes);  }}
                                >

                                    <ListItemText primary={classifiedStackSizes3p.classifiedStackSizes[selectedBigStack][selectedSmallStack][index][0]
                                                            +"-" + classifiedStackSizes3p.classifiedStackSizes[selectedBigStack][selectedSmallStack][index][1]
                                                            +"-" + classifiedStackSizes3p.classifiedStackSizes[selectedBigStack][selectedSmallStack][index][2]} />
                                </ListItemButton>
                            )
                        )}
                    </List>)
                    :
                    ""

                }



            </Grid>
        </Grid>

    )

}