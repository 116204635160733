
import { useState } from 'react';

import { useParams } from 'react-router-dom'
import queryString from 'query-string';
import { useNavigate } from "react-router-dom";

import Stack from '@mui/material/Stack';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

	
import Box from '@mui/material/Box';





const ActionsButtons = (props) => {

  const actions = props.actions
  const player = props.player
  const round = props.round
  const nodeIds = props.nodeIds
  const selectedAction = props.selectedAction
  const curNodeId = props.curNodeId
  console.log("curNodeIdsArr",curNodeId)
  

  const navigate = useNavigate();
  const { urlParamsStr } = useParams()

  let [buttonClicked , setButtonClicked] = useState(false);
  // let buttonClicked = false
  const onClick = (id) => {
    setButtonClicked(true)
    buttonClicked = true
    if (nodeIds[id] == 0) { // terminal node
      return
    }
    let urlParams = queryString.parse(urlParamsStr)

    urlParams.nodeId = nodeIds[id]
    navigate("/getNodeGame/" + queryString.stringify(urlParams), { replace: true })
    // setActive(id)
    // props.sendAction({ actionId: id, nodeId: props.nodeId })
  }

  function handleBoxClick() {
    console.log("buttonClicked",buttonClicked)
    if (buttonClicked) {
      
      buttonClicked = false
      setButtonClicked(false)
      return
    }
    console.log("curNodeId",curNodeId)
    let urlParams = queryString.parse(urlParamsStr)
    urlParams.nodeId = curNodeId
    navigate("/getNodeGame/" + queryString.stringify(urlParams), { replace: true })
  }

  function actionToString(id) {

    let actionType = actions[id].actionType
    switch (actionType) {
      case 'FoldAction':
        return "Fold";
        break;
      case 'CallAction':
        return "Call"

      case 'RaiseAction':
        return "Raise " + Math.round(actions[id].amount * 100) / 100
        break;
      case 'BetAction':
        return "Bet " + Math.round(actions[id].amount * 100) / 100
        break;
      case 'CheckAction':
        return "Check "
        break;

    }
  }

  let actionIds = Array.from(actions.keys())


  // const [active, setActive] = useState(-1);

  const nodeStyle = {
    // border: "solid",
    borderWidth: "2px",
    borderRadius: "5px"
  }

  return (
    <Box
    sx= {{
      bgcolor:"rgb(  248, 248, 248 )",
      '&:hover': {
        cursor:"pointer",
        bgcolor:"rgb(  238, 238, 238 )"
      },
      selected:{
        bgcolor:"red"
      }
    }}
    onClick={()=> handleBoxClick()}
     border={0} borderRadius={3} display="flex" flexDirection="column" alignItems="center">
      <div>Player {player}, round {round}</div>
    <Stack spacing={1} direction={"column"} p={0} component="span"  display={"flex"}  borderRadius={2} >
      

        {actionIds.map(id => (
          <>
  
            <ListItemButton 
              sx={{
                height:"20px",
                // bgcolor:"rgb( 0, 224, 255 )" , 
                borderRadius:"5px"
                }}  selected={selectedAction - 1 === id} onClick={() => onClick(id)} key={id}>
            <ListItemText  primary={actionToString(id)} />
            </ListItemButton>
          </>
        )
        )}

      {/* <ButtonToggle active={true} onClick={() => props.setShowId(props.nodeId)}>
        Show
      </ButtonToggle> */}
    </Stack>
    </Box>
  )
}
// export default ActionsButtons;

export default function ActionBar(props) {

  const actionsArr = props.actionsArr
  const playerArr = props.playerArr
  const roundArr = props.roundArr
  const nodeIdsArr = props.nodeIdsArr
  const selectedActions = props.selectedActions
  const curNodeIdsArr = props.curNodeIdsArr




  let ids = Array.from(actionsArr.keys())

  return (
    <div style={{
      // height: "800px",
      flexFlow: "column wrap",
      overflow: "auto",
  }}>
<Stack spacing={1} direction={"row"}>

    {
      ids.map(id => (
        <>
          <ActionsButtons curNodeId={curNodeIdsArr[id]} actions={actionsArr[id]} player={playerArr[id]} round={roundArr[id]} nodeIds={nodeIdsArr[id]} selectedAction={selectedActions[id]} />
          {console.log("id",curNodeIdsArr[id])}
        </>
        
      )
      )
    }
   
    </Stack>
    </div>
  )
}
