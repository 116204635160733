import { useState, useEffect } from 'react';


import { useParams } from 'react-router-dom'
import queryString from 'query-string';
import { useNavigate } from "react-router-dom";



import Button from '@mui/material/Button'

import ActionsButtons from './ActionsButtons.js';
import RangeTable2 from './RangeTable2.js';
import PokerTable from './PokerTable.js';

import BoardSelector, { Board } from './boardSelector.js';
import ActionBar from './ActionsButtons.js';

  

const nbBoardCard = [0 , 3 , 4 , 5]

// import ShowGameState from './ShowGameState.js';

// import treeJson from './data/gameTree2.json';

function App2(props) {

  // let strategyOnBoard = props.strategyOnBoard
  let residualRangeOnBoard = props.residualRangeOnBoard
  let values = props.values
  let bucketOfHand = props.bucketOfHand
  let round = props.round
  let actionsArr = props.actionsArr

  const curNodeIdsArr = props.curNodeIdsArr

  
  let roundArr = props.roundArr
  const playerArr = props.playerArr
  const nodeIdsArr = props.nodeIdsArr
  const selectedActions = props.selectedActions

  let strategyOnBoard = props.strategyOnBoard

  let selectedBoard = props.selectedBoard
  


  function boardToInt(board) {
    let boardInt = 0
    for (let i = 0; i < 5; i++) {
      boardInt += board[i] << 6 * i 
    }
    return boardInt
  }

  const { urlParamsStr } = useParams()
  const navigate = useNavigate();
  
  function getBoard( board ) {
    // setSelectedBoard(board)
    
    
    let urlParams = queryString.parse(urlParamsStr)
    let boardStr = board[0].toString()
    
    for (let i = 1; i < board.length; i++) {
      boardStr += "_" + board[i].toString()
    } 
    urlParams.board = boardStr
    navigate("/getNodeGame/" + queryString.stringify(urlParams) ,  { replace: true })
    
  }


  // const sendAction = (action) => {   
  //   let idx = action.nodeId

  //   if (idx == nodeArr.length - 1) {

  //     let child = nodeArr[nodeArr.length - 1].children[action.actionId]
  //     if (child.type == "InformationSetNode") {
  //       nodeArr.push(child)
  //     }
  //   } else {
  //     nodeArr = nodeArr.slice(0, idx + 1)
  //     let child = nodeArr[nodeArr.length - 1].children[action.actionId]
  //     if (child.type == "InformationSetNode") {
  //       nodeArr.push(child)
  //     }
  //   }
    
  //   setnodeArr(nodeArr)
  //   setShowId(nodeArr.length - 1)
  // }


  let ids = Array.from(actionsArr.keys())

  
  return (
    <div className="App" >
                <ActionBar 
            actionsArr={actionsArr}  
            playerArr={playerArr} 
            roundArr={roundArr} 
            nodeIdsArr={nodeIdsArr} 
            selectedActions={selectedActions}
            curNodeIdsArr={curNodeIdsArr}
          />
                  <div />



      <div style={{ display: "grid", gridTemplateColumns: "  10fr 3fr  "  }}>

{/* 
      <div style={{ display: "grid", gridTemplateColumns: "3fr 0.5fr 10fr "  }}>

        <div>
      <BoardSelector getBoard={getBoard}  selectedBoard={selectedBoard} /> 
      <Board sizeMul={0.8} selectedBoard={selectedBoard} /> 
      <PokerTable/>
      </div> */}

        {/* <div></div> */}


        <RangeTable2
          selectedBoard={selectedBoard.slice(0, nbBoardCard[round - 1]  ) }
          actions={actionsArr[actionsArr.length - 1]}
          values={values}
          strategyOnBoard={strategyOnBoard}
          residualRangeOnBoard={residualRangeOnBoard}
          bucketOfHand={bucketOfHand}
          key={urlParamsStr}
        />
        </div>

      </div>
  )
}
export default App2
