import { useState } from 'react';


// import strategy from './data/strategy.json';
import Button from '@mui/material/Button';
// import residualRange from './data/residualRange.json';


const RANK_TO_CHAR_Reversed = "AKQJT98765432"
const RANK_TO_CHAR = "23456789TJQKA"
const suitSymbols = ["♥", "♦", "♠", "♣"]


const ranks = Array.from(Array(13).keys())
const suits = Array.from(Array(4).keys())

function addvector(a, b) {
    return a.map((e, i) => e + b[i]);
}

function divVector(a, scalar) {
    return a.map((e, i) => e / scalar);
}

function mulVector(a, scalar) {
    return a.map((e, i) => e * scalar);
}

function makeCardString(cards){
    return RANK_TO_CHAR[getRank(cards[0])] + suitSymbols[getSuit(cards[0])] +   RANK_TO_CHAR[getRank(cards[1])] + suitSymbols[getSuit(cards[1])]
}

function getCardId(row, column) {
    if (row == -1 || column == -1) {
        return -1
    }
    return (12 - row) * 13 + 12 - column
}

function getrank1(holeCardsId) {
    return holeCardsId % 13
}

function getrank2(holeCardsId) {
    return (holeCardsId - (holeCardsId % 13)) / 13
}


function isSuited(holeCardsId) {
    let rank1 = getrank1(holeCardsId)
    let rank2 = getrank2(holeCardsId)
    return rank1 < rank2
}


function getNbCombo(holeCardsId) {
    if (getrank1(holeCardsId) == getrank2(holeCardsId)) {
        return 6
    } else if (isSuited(holeCardsId)) {
        return 4
    } else {
        return 12
    }
}

function makeCard(suit, rank) {
    return (rank << 2 | suit) + 1
}

function getSuit(cardId) {
    return (cardId - 1) & 3
}

function getRank(cardId) {
    return (cardId - 1) >> 2
}

function cardString(row, column) {
    if (row == column) {
        return RANK_TO_CHAR_Reversed[row] + RANK_TO_CHAR_Reversed[column]
    } else if (column > row) {
        return RANK_TO_CHAR_Reversed[row] + RANK_TO_CHAR_Reversed[column] + "s"
    } else {
        return RANK_TO_CHAR_Reversed[column] + RANK_TO_CHAR_Reversed[row] + "o"
    }
}

// for each isomorphic holecard return the non dead corresponding hands
function getHoleCardsArr(id, board) {
    if (id == -1) {
        return []
    }
    let holeCardsArr = []
    let rank1 = getrank1(id)
    let rank2 = getrank2(id)

    if (rank1 == rank2) {
        for (let suit1 = 0; suit1 < suits.length; suit1++) {
            for (let suit2 = 0; suit2 < suit1; suit2++) {
                if (suit1 == suit2) {
                    continue;
                }
                let card1 = makeCard(suit1, rank1)
                let card2 = makeCard(suit2, rank2)

                if (board.includes(card1) || board.includes(card2)) {
                    continue;

                }
                if (card1 < card2) {
                    [card1, card2] = [card2, card1]
                }

                holeCardsArr.push([card1, card2])
            }
        }

    } else if (!isSuited(id)) {
        for (let suit1 = 0; suit1 < suits.length; suit1++) {
            for (let suit2 = 0; suit2 < suits.length; suit2++) {
                if (suit1 == suit2) {
                    continue;
                }
                let card1 = makeCard(suit1, rank1)
                let card2 = makeCard(suit2, rank2)

                if (board.includes(card1) || board.includes(card2)) {
                    continue;
                }
                if (card1 < card2) {
                    [card1, card2] = [card2, card1]
                }
                holeCardsArr.push([card1, card2])
            }
        }

    } else if (isSuited(id)) {
        for (let suit = 0; suit < suits.length; suit++) {
            let card1 = makeCard(suit, rank1)
            let card2 = makeCard(suit, rank2)

            if (board.includes(card1) || board.includes(card2)) {
                continue;
            }

            if (card1 < card2) {
                [card1, card2] = [card2, card1]
            }
            holeCardsArr.push([card1, card2])
        }

    }
    return holeCardsArr
}



function CardCell(props) {



    const cellStrategy = props.cellStrategy
    const cellResidualRange = props.cellResidualRange
    const actions = props.actions
    const cellValue = props.cellValue


    //   fold                 call            raise 1          raise 2
    // const actionColors = [ " rgb(82, 245, 243) " , " rgb(14,197,14) ", " rgb(250,80,80) " , " rgb(186, 0, 0) "  ]
    let actionColors = props.actionColors

    const nbActions = actions.length

    const probabilities = new Array(nbActions).fill(0);
    probabilities[0] = cellStrategy[0] * 100;

    for (let a = 1; a < probabilities.length; a++) {
        probabilities[a] = probabilities[a - 1] + cellStrategy[a] * 100;
    }

    var backGroundImgStr = "linear-gradient( to left, ";
    backGroundImgStr += actionColors[0] + probabilities[0] + "%,";
    for (let a = 1; a < probabilities.length - 1; a++) {
        backGroundImgStr += actionColors[a] + probabilities[a - 1] + "% " + probabilities[a] + "%,"
    }
    backGroundImgStr += actionColors[nbActions - 1] + probabilities[nbActions - 2] + "% )"


    const cardsStyle = {
        backgroundColor: "rgb(  210, 211, 208 ) ",
        aspectRatio: "13/9",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
        backgroundSize: "100% " + cellResidualRange * 100 + "%",
        // backgroundSize:"100% 50%" ,
        backgroundImage: backGroundImgStr,
        borderWidth: "1px 1px",
        color: 'black',
        position: "relative"
    }

    const nodeValueText = {
        // float: "right",
        position: "absolute",
        bottom: "0px",
        right: "0px",
        color: "black",
        fontSize: "0.8em",
    }

    // function handleClick() {
    //     props.setSelectedCell({row:props.row, column:props.column})
    // }

    return (
        <button onClick={x => {
            props.handleClick();
            console.log("cellStrategy", cellStrategy);
        }} style={cardsStyle
        } > <div>  <div>{props.children} </div> <div style={nodeValueText}> {cellValue}</div> </div> </button>
    )
}

function RangeTable2(props) {

    let actions = props.actions
    let selectedBoard = props.selectedBoard
    let values = props.values


    let strategyOnBoard = props.strategyOnBoard
    let residualRangeOnBoard = props.residualRangeOnBoard

    let bucketOfHand = props.bucketOfHand



    const [selectedCell, setSelectedCell] = useState({ row: -1, column: -1 })



    function actionToString(id) {
        let actionType = actions[id].actionType
        switch (actionType) {
            case 'FoldAction':
                return "Fold";
                break;
            case 'CallAction':
                return "Call"

            case 'RaiseAction':
                return "Raise " + actions[id].amount
                break;
            case 'BetAction':
                return "Bet " + actions[id].amount
                break;
            case 'CheckAction':
                return "Check "
                break;
        }
    }


    let sum = 0
    let percentageAction = new Array(actions.length).fill(0);
    let totalCards = 0

    // let strategy = new Float64Array(169)
    let strategy = new Array(169)
    let residualRange = new Float64Array(169)
    let valuesAbst = new Float64Array(169)
    residualRange.fill(0)


    for (let i = 0; i < 169; i++) {
        strategy[i] = new Float64Array(actions.length)
        strategy[i].fill(0)

    }



    for (let id = 0; id < 169; id++) {
        let cardsArr = getHoleCardsArr(id, props.selectedBoard)
        if (cardsArr.length == 0) {
            residualRange[id] = 0
        }

        for (let i = 0; i < cardsArr.length; i++) {
            let card1 = cardsArr[i][0]
            let card2 = cardsArr[i][1]
            let weightedStrategyOnBoard = mulVector(strategyOnBoard[card1 - 1][card2 - 1], residualRangeOnBoard[card1 - 1][card2 - 1])
            strategy[id] = addvector(strategy[id], weightedStrategyOnBoard)
            if (values.length > 0) {
                valuesAbst[id] += residualRangeOnBoard[card1 - 1][card2 - 1] * values[card1 - 1][card2 - 1]
            }
            residualRange[id] += residualRangeOnBoard[card1 - 1][card2 - 1]
        }
        strategy[id] = divVector(strategy[id], residualRange[id])
        if (values.length > 0) {
            valuesAbst[id] /= residualRange[id]
            valuesAbst[id] = Math.round(valuesAbst[id] * 100) / 100
        }

        residualRange[id] /= getNbCombo(id)

    }



    let totalResidualRange = 0
    for (let card1 = 1; card1 <= 52; card1++) {
        for (let card2 = 1; card2 <= card1 - 1; card2++) {
            if (selectedBoard.includes(card1) || selectedBoard.includes(card2) || residualRangeOnBoard[card1 - 1][card2 - 1] == 0) {
                continue
            }

            for (let a = 0; a < actions.length; a++) {

                percentageAction[a] += strategyOnBoard[card1 - 1][card2 - 1][a] * residualRangeOnBoard[card1 - 1][card2 - 1]
            }
            totalResidualRange += residualRangeOnBoard[card1 - 1][card2 - 1]

        }
    }

    for (let a = 0; a < actions.length; a++) {
        percentageAction[a] /= totalResidualRange
        percentageAction[a] = Math.round(percentageAction[a] * 1000) / 10
    }


    if (false) {// if (props.node.round == 1) {
        for (let a = 0; a < actions.length; a++) {
            totalCards = 0
            for (let col = 0; col < 13; col++) {
                for (let row = 0; row < 13; row++) {
                    let id = getCardId(row, col)
                    if (col == row) {
                        percentageAction[a] += strategy[id][a] * 6 * residualRange[id];
                        totalCards += 6 * residualRange[id]
                    } else if (col > row) {
                        percentageAction[a] += strategy[id][a] * 4 * residualRange[id];
                        totalCards += 4 * residualRange[id]
                    } else {
                        percentageAction[a] += strategy[id][a] * 12 * residualRange[id]
                        totalCards += 12 * residualRange[id]

                    }
                }
            }
            percentageAction[a] /= totalCards
            percentageAction[a] = Math.round(percentageAction[a] * 1000) / 10
        }

    }

    let actionColors = []

    const actionColorFold = " rgb(82, 245, 243)"
    const actionColorCall = " rgb( 62, 210, 44 )"
    // const actionColorsRaise = [" rgb(190, 138, 242) " , "rgb(194, 57, 247)" ]
    const actionColorsRaise = [" rgb(190, 138, 242) ", "rgb(194, 57, 247)", "rgb(219, 13, 202)", "rgb(237, 6, 101)", "rgb(255, 0, 0)", "rgb(218, 6, 13)", "rgb(181, 13, 27)"]


    let nbRaiseAction = 0
    let nbRaiseColors = actionColorsRaise.length
    for (let a = 0; a < actions.length; a++) {
        if (actions[a].actionType == "FoldAction") {
            actionColors.push(actionColorFold)
        } else if (actions[a].actionType == "CallAction" || actions[a].actionType == "CheckAction") {
            actionColors.push(actionColorCall)
        } else if (actions[a].actionType == "BetAction" || actions[a].actionType == "RaiseAction") {
            actionColors.push(actionColorsRaise[nbRaiseAction])
            nbRaiseAction += 1
        }
    }
    let actionsIDs = Array.from(Array(actions.length).keys())



    if (strategy === null) {
        return <div> Loading .... </div>
    }

    let cardId = getCardId(selectedCell.row, selectedCell.column)


    let holeCardsArr = getHoleCardsArr(cardId, props.selectedBoard)



    return (
        <>
            <div style={{ display: "grid", gridTemplateColumns: "4fr 2fr" }}>
                <div>
                    <div style={{ display: "grid", borderWidth: "2px", backgroundColor: "black", gridGap: "3px", border: "solid", gridTemplateColumns: "repeat(" + actions.length + ",1fr)" }}>
                        {actionsIDs.map(id => (
                            <div style={{ backgroundColor: actionColors[id], display: "grid", gridTemplateColumns: "repeat(2,1fr)" }} >
                                <div>  {actionToString(id) + ": "}  </div>
                                <div> {percentageAction[id] + "%"}  </div>
                            </div>))}
                    </div>
                    <div style={{ display: "grid", gridGap: "1px", backgroundColor: "black", gridTemplateColumns: "repeat(13,1fr) ", border: "solid", borderWidth: "2px", gridTemplateRows: "1fr 1fr 1fr " }}>
                        {ranks.map(row => (
                            <>
                                {
                                    ranks.map(column => (
                                        <CardCell
                                            cellValue={values.length == 0 ? "" : valuesAbst[getCardId(row, column)]}
                                            setSelectedCell={setSelectedCell}
                                            actions={actions}
                                            actionColors={actionColors}
                                            cellStrategy={strategy[getCardId(row, column)]}
                                            cellResidualRange={residualRange[getCardId(row, column)]}
                                            handleClick={() => {

                                                setSelectedCell({ row: row, column: column });

                                            }


                                            }
                                        > {cardString(row, column)} </CardCell>
                                    ))
                                }</>
                        ))}
                    </div>
                </div>
                {(selectedCell.row == -1 ?
                    <h2> </h2>
                    :
                    <div >
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", width: "130px" }}>
                            {
                                holeCardsArr.map(cards => (
                                    <>
                                        <CardCell
                                            cellValue={values.length == 0 ? "" : values[cards[0] - 1][cards[1] - 1]}
                                            actions={actions}
                                            actionColors={actionColors}
                                            cellStrategy={strategyOnBoard[cards[0] - 1][cards[1] - 1]}
                                            cellResidualRange={residualRangeOnBoard[cards[0] - 1][cards[1] - 1]}
                                            handleClick={() =>
                                                console.log(cards)
                                            }
                                        > {RANK_TO_CHAR[getRank(cards[0])]} {suitSymbols[getSuit(cards[0])]}  {RANK_TO_CHAR[getRank(cards[1])]} {suitSymbols[getSuit(cards[1])]} </CardCell>
                                        <div>
                                            b {props.bucketOfHand[cards[0] - 1][cards[1] - 1]}
                                        </div>
                                    </>
                                ))
                            }


                        </div>
                    </div>)}
            </div>

        </>
    )
}

RangeTable2.defaultProps = {
    strategyOnBoard: null,
    residualRangeOnBoard: null,
}



export default RangeTable2

