import { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';


import { useParams } from 'react-router-dom'

import CircularProgress from '@mui/material/CircularProgress';

 
import queryString from 'query-string';
import { useNavigate } from "react-router-dom";
import App2 from './App2';

// localhost:3000/getNodeGame/gameName=25_5_25_bb3p-nbIter10pow6.strat&nodeId=1&board=10_11_12_13_14
function GetGame() {



  const { urlParamsStr } = useParams()

  
  
  let urlParams = queryString.parse(urlParamsStr)



  const navigate = useNavigate();
  const location = useLocation();

  

  let gameName = urlParams.gameName

  const [solutionData, setSolutionData] = useState(null)
  // const [_urlParamsStr, set_urlParamsStr] = useState(null)
  // set_urlParamsStr(urlParamsStr)


  useEffect(() => {
    console.log('Location changed!', location.pathname);
    // const url = "http://localhost:8080/api/pokerSolver/getNodeStrategy/" + gameName + "/" + urlParams.nodeId + "/" + urlParams.board  ;
    const url = "http://gto-spin.com:8080/api/pokerSolver/getNodeStrategy/" + gameName + "/" + urlParams.nodeId + "/" + urlParams.board  ;
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const d = await response.json();
        setSolutionData(d)

      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();    
  }, [location.pathname]);

  if (solutionData == null ) {

    return (
      <CircularProgress/>
    // <div> loading ... </div>
      )


  }

    return (
      <App2
        values={solutionData.values}
        selectedActions={solutionData.selectedActions}
        roundArr={solutionData.roundArr}
        nodeIdsArr={solutionData.nodeIdsArr} 
        playerArr={solutionData.playerArr}
        strategyOnBoard={solutionData.strategyOnBoard}
        residualRangeOnBoard={solutionData.residualRangeOnBoard}
        bucketOfHand={solutionData.bucketOfHand}
        round={solutionData.round}
        actionsArr={solutionData.actionsArr}
        selectedBoard={solutionData.board}
        curNodeIdsArr={solutionData.curNodeIdsArr}
        key={location.key}
      />
    );
  }
  
  export default GetGame;
  