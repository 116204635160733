import styled from "styled-components";
import { useState } from 'react';

const theme = {
  blue: {
    default: "#52f5f3",
    hover: "#189997"
  },
  pink: {
    default: "#e91e63",
    hover: "#ad1457"
  }
};



const Button = styled.button`
  background-color: ${(props) => theme[props.theme].default};
  color: black;
  padding: 5px 15px;
  border-radius: 5px;
  outline: 0;
  margin: 10px 0px;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  &:hover {
    background-color: ${(props) => theme[props.theme].hover};
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;


Button.defaultProps = {
  theme: "blue"
};


const ButtonToggle = styled(Button)`
  opacity: 0.9;
  ${({ active }) =>
    active &&
    `
    background-color:rgb(14, 173, 181);
    opacity: 1; 
  `}
  
`;


const Child = (props) => {

  const onClick = (id) => {
    setActive(id)
    props.sendAction({ actionId: id, nodeId: props.nodeId })
  }

  function actionToString(id) {

    let actionType = props.node.actions[id].actionType
    switch (actionType) {
      case 'FoldAction':
        return "Fold";
        break;
      case 'CallAction':
        return "Call"

      case 'RaiseAction':
        return "Raise " + props.node.actions[id].amount
        break;
      case 'BetAction':
        return "Bet " + props.node.actions[id].amount
        break;
      case 'CheckAction':
        return "Check "
        break;

    }
  }

  let actionIds = Array.from(props.node.actions.keys())

  const actions = ["FoldAction", "BetAction10.0"]
  const [active, setActive] = useState(-1);

  const nodeStyle = {
    border: "solid",
    borderWidth: "2px",
    borderRadius: "5px"
  }
  return (
    <div style={nodeStyle}>
      <div> Player {props.node.player}, </div>
      <strong> nodeId {props.node.id}, </strong>
      <strong> round {props.node.round} </strong>
      <br></br>
      {actionIds.map(id => (
        <>
          <ButtonToggle active={active === id} onClick={() => onClick(id)} key={id}>
            {actionToString(id)}
          </ButtonToggle>
        </>
      )
      )}
      <ButtonToggle active={true} onClick={() => props.setShowId(props.nodeId)}>
        Show
      </ButtonToggle>
    </div>
  )
}
export default Child