import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import DialogTitle from '@mui/material/DialogTitle';

import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { blue, yellow } from '@mui/material/colors';

// const RANK_TO_CHAR = "AKQJT98765432"

const suitSymbols = ["♥", "♦", "♠", "♣"]
const suitColors = ["red", "rgb(0, 224, 255)", "black", "rgb(43, 216, 12)"]
const RANK_TO_CHAR = "23456789TJQKA"


function makeCard(suit, rank) {
  return (rank << 2 | suit) + 1
}

function getSuit(cardId) {
  return (cardId - 1) & 3
}

function getRank(cardId) {
  return (cardId - 1) >> 2
}


function sampleCards(nbCard) {
  const deck = Array(52).fill(true)
  const sampledCards = []
  for (let c = 0; c < nbCard; c++) {
    while (true) {
      
      let card = Math.floor(Math.random() * 52)
      if (deck[card]) {
        deck[card] = false
        sampledCards.push(card + 1)
        break
      }
    }
  }
  return sampledCards
}

export function CardComponent(props) {

  const { onClick, cardId, isSelected } = props

  const [_isSelected, setIsSelected] = React.useState(isSelected);


  const suit = getSuit(cardId)
  const rank = getRank(cardId)

  function handleClick() {
    onClick()

  }

  if (cardId == 0) {
    return (
      <ToggleButton
        sx={{ aspectRatio: "4/3", width: "65px" }}
        selected={isSelected}
        onClick={handleClick}
        // style={{ borderRadius: "5px", , color: "white", backgroundColor: "rgb(155, 89, 182)" }}>
        style={{ borderRadius: "5px", aspectRatio: "4/3", width: "65px" }}>
        {"X"}
      </ToggleButton>
    )
  } else {
    // console.log("isSelected = " + _isSelected)
    // console.log('%c' + RANK_TO_CHAR[rank] + suitSymbols[suit], 'color:' + suitColors[suit])
    const color = (_isSelected ? suitColors[suit] : "white")
    // console.log("color = " + color)
    return (
      <ToggleButton
        sx={{ aspectRatio: "4/3", width: (props.sizeMul * 65) + "px", color: "white", backgroundColor: suitColors[suit], '&:hover': { color: suitColors[suit] }, '&.Mui-selected, &.Mui-selected:hover': { color: suitColors[suit] } }}
        selected={isSelected}
        onClick={handleClick}
      >
        {RANK_TO_CHAR[rank]} {suitSymbols[suit]}
      </ToggleButton>
    )
  }
}

CardComponent.defaultProps = {
  sizeMul: 1,
}

export function Board(props){
  
  const sizeMul = props.sizeMul
  const selectedBoard = props.selectedBoard

  function handleBoardClick() {

  }
  return (
    <div style={{
      display: "grid",
      gridGap: "0.5px",
      gridTemplateColumns: "2.18fr 1fr 1fr",
    }}>

      <Card sx={{ maxWidth: "230px" }}>
        <CardContent>
          <Typography gutterBottom variant="boddy" component="div">
            Flop
          </Typography>
          <div style={{
            display: "grid",
            gridGap: "1px",
            gridTemplateColumns: "1fr 1fr 1fr",
          }}>
            <CardComponent
            sizeMul={sizeMul} 
              cardId={selectedBoard[0]}
              onClick={() => handleBoardClick(0)}
            />
            <CardComponent
              sizeMul={sizeMul}
              cardId={selectedBoard[1]}
              onClick={() => handleBoardClick(1)} />
            <CardComponent
              sizeMul={sizeMul}
              cardId={selectedBoard[2]}
              onClick={() => handleBoardClick(2)} />
          </div>
        </CardContent>
      </Card>

      <Card sx={{ maxWidth: "100px" }}>
        <CardContent>
          <Typography gutterBottom variant="boddy" component="div">
            Turn
          </Typography>
          <div style={{
            display: "grid",
            gridGap: "0.5px",
            gridTemplateColumns: "1fr",
          }}>
            <CardComponent
              sizeMul={sizeMul}
              cardId={selectedBoard[3]}
              onClick={() => handleBoardClick(3)} />
          </div>
        </CardContent>
      </Card>

      <Card sx={{ maxWidth: "100px" }}>
        <CardContent>
          <Typography gutterBottom variant="boddy" component="div">
            River
          </Typography>
          <div style={{
            display: "grid",
            gridGap: "0.5px",
            gridTemplateColumns: "1fr",
          }}>
            <CardComponent
              sizeMul={sizeMul}
              cardId={selectedBoard[4]}
              onClick={() => handleBoardClick(4)} />
          </div>
        </CardContent>
      </Card>

    </div>
  )
}

function BoardSelectorDialog(props) {
  const { onClose, open, getBoard } = props;

  let [boardId, setBoardId] = React.useState(0);
  const [selectedBoard, setSelectedBoard] = React.useState(props.selectedBoard);

  const handleClose = () => {
    getBoard(selectedBoard)
    onClose();
  };


  let ranks = Array.from(Array(13).keys())
  let suits = Array.from(Array(4).keys())




  function handleClick(suit, rank) {
    if (boardId == -1) {
      setBoardId(0)
      boardId = 0
    }
    
    selectedBoard[boardId] = makeCard(suit, rank)
    setSelectedBoard(selectedBoard)
    setBoardId((boardId + 1) % 5)
    // console.log('%c' + RANK_TO_CHAR[rank] + suitSymbols[suit], 'color:' + suitColors[suit])
    // console.log("boardId = " + boardId)
  }

  function handleBoardClick(id) {
    setBoardId(id)
    // console.log("Click bord card id = " + id)
  }




  return (
    <Dialog fullWidth={true} maxWidth={"md"} onClose={handleClose} open={open}>
      <DialogTitle>Select Board</DialogTitle>
      <div style={{
        display: "grid",
        gridGap: "0.5px",
        gridTemplateColumns: "2.18fr 1fr 1fr 4fr",
      }}>

        <Card sx={{ maxWidth: "230px" }}>
          <CardContent>
            <Typography gutterBottom variant="boddy" component="div">
              Flop
            </Typography>
            <div style={{
              display: "grid",
              gridGap: "1px",
              gridTemplateColumns: "1fr 1fr 1fr",
            }}>
              <CardComponent
                cardId={selectedBoard[0]}
                onClick={() => handleBoardClick(0)}
                isSelected={boardId == 0}
              />
              <CardComponent
                cardId={selectedBoard[1]}
                onClick={() => handleBoardClick(1)}
                isSelected={boardId == 1} />
              <CardComponent
                cardId={selectedBoard[2]}
                onClick={() => handleBoardClick(2)}
                isSelected={boardId == 2} />
            </div>
          </CardContent>
        </Card>

        <Card sx={{ maxWidth: "100px" }}>
          <CardContent>
            <Typography gutterBottom variant="boddy" component="div">
              Turn
            </Typography>
            <div style={{
              display: "grid",
              gridGap: "0.5px",
              gridTemplateColumns: "1fr",
            }}>
              <CardComponent
                cardId={selectedBoard[3]}
                onClick={() => handleBoardClick(3)}
                isSelected={boardId == 3} />
            </div>
          </CardContent>
        </Card>

        <Card sx={{ maxWidth: "100px" }}>
          <CardContent>
            <Typography gutterBottom variant="boddy" component="div">
              River
            </Typography>
            <div style={{
              display: "grid",
              gridGap: "0.5px",
              gridTemplateColumns: "1fr",
            }}>
              <CardComponent
                cardId={selectedBoard[4]}
                onClick={() => handleBoardClick(4)}
                isSelected={boardId == 4} />
            </div>
          </CardContent>
        </Card>
        <div>
        <Button sx={{ 
                  margin: "auto",
                  }}
                onClick={() => {
                  setBoardId(-1)
                  setSelectedBoard( sampleCards(5) );
                }
                }
                variant="outlined"
                > Sample </Button>
        </div>
        
      </div>
      <div style={{
        display: "grid",
        gridGap: "0px",
        // backgroundColor: "black",
        gridTemplateColumns: "repeat(13,1fr) ",
        // border: "solid", 
        // borderWidth: "2px", 
        // gridTemplateRows: "1fr 1fr 1fr " 
      }}>
        {suits.map(suit => (
          <>
            {ranks.map(rank => (
              <CardComponent
                cardId={makeCard(suit, rank)}
                isSelected={selectedBoard.includes(makeCard(suit, rank))}
                onClick={() => handleClick(suit, rank)} />
              // <button
              //   onClick={()=>handleClick( suit, rank )}
              //   style={{borderRadius: "5px",aspectRatio:"4/3", width: "65px" , color:"white", backgroundColor:suitColors[suit]}}> 
              //     {RANK_TO_CHAR[rank]} {suitSymbols[suit]}
              // </button>
            ))}
          </>
        ))}

      </div>


    </Dialog>
  );
}

BoardSelectorDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function BoardSelector(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <br />
      <Button variant="outlined" onClick={handleClickOpen}>
        Select Board
      </Button>
      <BoardSelectorDialog
        selectedBoard={props.selectedBoard}
        getBoard={props.getBoard}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}