import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import PersistentDrawerLeft from "./Drawer"
import GetGame from './GetGame';
import { BrowserRouter as Router, Routes, Route ,Navigate } from 'react-router-dom'


import reportWebVitals from './reportWebVitals';
 


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={ <Navigate to="/getNodeGame/gameName=5.0_5.0_5.0_bb3p-nbIter10pow8&nodeId=1&board=10_11_12_13_14" />} />
        {/* <Route path="/getNodeGame/:urlParamsStr" element={<GetGame />} /> */}
        <Route path="/getNodeGame/:urlParamsStr" element={<PersistentDrawerLeft page="viewSolution" />} />
        <Route path="/drawer/getNodeGame/:urlParamsStr" element={<PersistentDrawerLeft page="viewSolution"  />} />
        <Route path="/drawer/selectSolution/:urlParamsStr" element={<PersistentDrawerLeft page="selectSolution" />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();
